<template>
  <div class="px-10">
    <div align="center">
      <v-col cols="10">
        <v-progress-linear :value="valueDeterminate" height="30">
          <strong>ตั้งค่าสำเร็จ {{ Math.ceil(valueDeterminate) }}%</strong>
        </v-progress-linear>
      </v-col>
    </div>
    <v-row class="mb-7">
      <v-col cols="1"></v-col>
      <v-col cols="2"><div class="text-h6">1. ตั้งค่าสาขา</div></v-col>
      <v-col cols="4"
        ><div class="text-h6">2. เปิดใช้งาน พ.ร.บ./ประกันภัย</div></v-col
      >
      <v-col cols="2"><div class="text-h6">3. ตั้งค่าธุรกิจ</div></v-col>
      <!-- <v-col cols="2"><div class="text-h6">4. ตั้งค่าเอกสาร</div></v-col> -->
      <v-col cols="2"
        ><div class="text-h5 primary--text">4. ราคาสินค้า</div></v-col
      ><v-col cols="1"></v-col>
    </v-row>
    <div align="center" class="text-h5 mb-4 red--text">
      กรอกราคาสินค้าหลัก (ส่วนสินค้าอื่นๆ สามารถใส่ข้อมูลได้ที่หน้าสินค้า)
    </div>
    <v-row>
      <v-col cols="12">
        <div class="text-h4 primary--text mb-5">4. ราคาสินค้าหลัก</div>
      </v-col></v-row
    >

    <v-row class="ml-5"
      ><v-col cols="5" sm="4" md="5"
        ><v-text-field
          name=""
          label="ค่าฝากต่อภาษี รถจักรยานยนต์"
          outlined
          dense
          v-model="motorcycle_extension_tax_fee"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
        <v-text-field
          name=""
          label="ค่าฝากต่อภาษี รถยนต์"
          outlined
          dense
          v-model="car_extension_tax_fee"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        /><v-text-field
          name=""
          label="ค่าจัดทำพ.ร.บ. รถจักรยานยนต์"
          outlined
          dense
          v-model="motorcycle_3rdinsurance"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
        <v-col>
          <v-row>
            <div class="mt-1">
              มีบริการส่งเอกสารให้ลูกค้า (ไปรษณีย์, Kerry, Flash, Best, J&T,
              DHL)
            </div>
            <v-radio-group class="mt-0 ml-10" row v-model="parcel_available">
              <v-radio name="" label="มี" :value="true"></v-radio>
              <v-radio
                name=""
                label="ไม่มี"
                :value="false"
              ></v-radio> </v-radio-group></v-row
        ></v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()"
          >ย้อนกลับ</v-btn
        >
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          เสร็จสิ้น
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    branch_id: 0,
    valueDeterminate: 100,
    motorcycle_3rdinsurance: 0,
    car_extension_tax_fee: 0,
    motorcycle_extension_tax_fee: 0,
    parcel_available: false,
    setup_wizard: 0,
  }),
  mounted() {
    this.getData();
    this.getCheck();
  },
  methods: {
    async getCheck() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/check`, body)
        .then((res) => {
          this.setup_wizard = res.result;
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          {
            noTimestamp: true,
          }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/get_wizard5`, body)
        .then((res) => {
          //this.list = res.result;
          console.log(res.result);
          this.motorcycle_extension_tax_fee =
            res.result.motorcycle_extension_tax_fee;
          this.car_extension_tax_fee = res.result.car_extension_tax_fee;
          this.motorcycle_3rdinsurance = res.result.motorcycle_3rdinsurance;
        })
        .catch((err) => {
          console.log('err', err);
        });
      this.loading = false;
    },
    async save() {
      let fill = {
        branch_id: this.$route.params.id,
        motorcycle_extension_tax_fee: this.motorcycle_extension_tax_fee,
        car_extension_tax_fee: this.car_extension_tax_fee,
        motorcycle_3rdinsurance: this.motorcycle_3rdinsurance,
        parcel_available: this.parcel_available,
        setup_wizard: this.setup_wizard,
      };
      if (fill.setup_wizard != 4) {
        fill.setup_wizard = this.setup_wizard;
      } else {
        fill.setup_wizard = 6;
      }
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/setup_wizard5`, body)
        .then((res) => {
          this.$alertSuccess({
            title: 'ตั้งค่าเรียบร้อยแล้ว กรุณาล็อกอิน เพื่อใช้งาน',
          });
          setTimeout(() => {
            window.location.replace(`/login`);
          }, 2000);
        })
        .catch((err) => {
          this.$alertServerError({ title: err.error_message });
        });
    },
    async close() {
      let fill = {
        branch_id: this.$route.params.id,
        setup_wizard: 3,
      };
      let body = {
        token: this.$jwt.sign(fill, this.$privateKey, {
          noTimestamp: true,
        }),
      };
      await this.$axios
        .post(`${this.$baseUrl}/wizard/set_setup_wizard`, body)
        .then((res) => {
          this.$router.push({ name: 'setup-business' });
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
  },
};
</script>
